:root {
  /* Minimal color palette inspired by rauchg.com */
  --primary-color: #000000;
  --primary-hover: #666666;
  --background-color: #ffffff;
  --content-background: #ffffff;
  --card-background: #fafafa;
  --text-color: #000000;
  --secondary-text: #666666;
  --border-color: #eaeaea;
  --header-height: 64px;
  --content-max-width: 700px;
  --content-text-width: 550px;
}

/* Site-wide styling */
.App {
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--background-color);
  color: var(--text-color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  line-height: 1.6;
}

.Header {
  border-bottom: 1px solid var(--border-color);
  position: sticky;
  top: 0;
  background: var(--content-background);
  z-index: 10;
}

.HeaderContent {
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: var(--content-text-width);
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;
}

.HeaderTitle {
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-color);
  text-decoration: none;
}

.HeaderNav {
  display: flex;
  gap: 1.5rem;
}

.AppBody {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  max-width: var(--content-text-width);
  margin: 0 auto;
  width: 100%;
}

/* Header text for a page */
.ContentTitle {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: var(--text-color);
  line-height: 1.2;
}

/* Main page content */
.Content {
  max-width: var(--content-text-width);
  width: 100%;
  background: var(--content-background);
}

.ContentText {
  max-width: var(--content-text-width);
  position: relative;
  z-index: 0;
}

/* Content sections */
.ContentSection {
  position: relative;
  margin-bottom: 2rem;
  padding: 0;
  background: var(--content-background);
}

.ContentSection:first-of-type {
  margin-top: 0;
}

.ContentSection:last-child {
  margin-bottom: 0;
}

.ContentSection h3 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

.ContentSection p {
  font-size: 0.875rem;
  line-height: 1.6;
  color: var(--secondary-text);
  margin: 0;
}

/* Project listing */
.ProjectList {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: var(--content-text-width);
}

.ProjectCard {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 1rem;
}

.ProjectInfo {
  display: flex;
  align-items: baseline;
}

.ProjectTitle {
  color: var(--text-color);
  text-decoration: none;
  font-size: 1rem;
}

.ProjectTitle:hover {
  color: var(--primary-hover);
}

.ProjectDescription {
  color: var(--secondary-text);
  font-size: 0.875rem;
  line-height: 1.6;
  margin-left: 0;
  margin-top: -1.5rem;
  max-width: var(--content-text-width);
}

.ProjectMeta {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.ProjectLink {
  color: var(--secondary-text);
  text-decoration: none;
  font-size: 0.875rem;
  white-space: nowrap;
}

.ProjectLink:hover {
  color: var(--text-color);
}

.AboutHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
  width: 100%;
}

.AboutImage {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  object-fit: cover;
  float: right;
  margin: 0 0 1.5rem 1.5rem;
  shape-outside: circle(70px at 70px 70px);
  position: relative;
  z-index: 1;
}

/* Responsive design */
@media (max-width: 768px) {
  .AppBody {
    padding: 1.5rem 1rem;
  }
  
  .ContentTitle {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }
  
  .HeaderContent {
    padding: 0 1rem;
  }

  .AboutHeader {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
  }

  .AboutImage {
    width: 180px;
    height: 180px;
    float: none;
    display: block;
    margin: 0 auto 2rem;
  }

  .ContentSection:first-of-type {
    margin-top: 0;
  }
}

