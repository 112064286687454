/* nav button styling */
.NavButton {
  text-align: center;
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: 1rem;
  color: var(--secondary-text);
  cursor: pointer;
  text-decoration: none;
}

.NavButton:hover {
  color: var(--text-color);
}

/* styling for the navigation pane as a whole */
.NavPane {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

/* styling for the text that appears above nav buttons */
.NameText {
  /* give a little space between text and buttons*/
  padding-bottom: 2px;

  /* set font size */
  font-size: small;
}

/* container for nav buttons */
.NavButtonContainer {
  text-decoration: none;
}

/* element in list of books/links */
.LinkEl {
  font-size: 1rem;
  margin-bottom: 0.75rem;
  padding: 0.75rem;
  background: var(--card-background);
  border: 1px solid var(--border-color);
}

/* Responsive design */
@media (max-width: 768px) {
  .NavPane {
    gap: 1rem;
  }

  .NavButton {
    font-size: 0.875rem;
  }
}